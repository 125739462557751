// Admin
import Home from "../pages/admin/Home";

//Customer

// Auth
import SignIn from "../pages/auth/signIn/SignIn";

import {
  faArchive,
  faCalendar,
  faCogs,
  faHome,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import WorkOrders from "../pages/admin/WorkOrders";
import WorkOrderDetails from "../pages/admin/WorkOrderDetails";
import Users from "../pages/admin/Users";
import Customers from "../pages/admin/Customers";
import CustomerDetails from "../pages/admin/CustomerDetails";
import Cranes from "../pages/admin/Cranes";
import Schedule from "../pages/admin/calendar/Schedule";
import PayrollReport from "../pages/admin/reports/PayrollReport";
import InspectionPointsManager from "../pages/admin/inspectionPointsManager/InspectionPointsManager";
import RecurringWorkOrders from "../pages/admin/calendar/RecurringWorkOrders";
import GeneralSettings from "../pages/admin/settings/GeneralSettings";
import TechnicianDailyReport from "../pages/admin/reports/TechnicianDailyReport";
import JobCostReport from "../pages/admin/reports/JobCostReport";
import PayrollManagement from "../pages/admin/settings/PayrollManagement";
import EmailNotifications from "../pages/admin/settings/EmailNotifications";
import DailyUsageReport from "../pages/admin/reports/DailyUsageReport";
import NoLeadTrips from "../pages/admin/settings/NoLeadTrips";
import PayCorReport from "../pages/admin/reports/PayCorReport";
import ActivityEmails from "../pages/admin/settings/ActivityEmails";
import AuditMergedLocations from "../pages/admin/settings/AuditMergedLocations";
import Crane from "../components/admin/cranes/craneConfig/Crane";
import CalendarGrouping from "../pages/admin/settings/calendarGrouping/CalendarGrouping";

const USER_ROLE_ADMIN = 5;
const USER_ROLE_EXECUTIVE = 1;
const USER_ROLE_MANAGER = 2;
const USER_ROLE_CONTROLLER = 4;
const USER_ROLE_REGIONAL_MANAGER = 7;
const USER_ROLE_DEVELOPERS = 6;
const USER_ROLE_PAYROLL_MANAGER = 8;

// Routes
export const adminRoutes = [
  {
    path: "/home",
    name: "Home",
    sidebar: false,
    component: Home,
  },
  {
    path: "/work-orders",
    name: "Work Orders",
    icon: faHome,
    sidebar: true,
    entries: [
      {
        icon: null,
        name: "All",
        path: `/work-orders/all`,
        component: WorkOrders,
        sidebar: true,
        roles: [
          USER_ROLE_ADMIN,
          USER_ROLE_EXECUTIVE,
          USER_ROLE_MANAGER,
          USER_ROLE_REGIONAL_MANAGER,
          USER_ROLE_DEVELOPERS,
          USER_ROLE_PAYROLL_MANAGER,
        ],
      },
      {
        icon: null,
        badgeColor: "secondary",
        name: "Recently Added",
        path: `/work-orders/recently-added`,
        component: WorkOrders,
        sidebar: true,
        roles: [
          USER_ROLE_ADMIN,
          USER_ROLE_EXECUTIVE,
          USER_ROLE_MANAGER,
          USER_ROLE_REGIONAL_MANAGER,
          USER_ROLE_DEVELOPERS,
          USER_ROLE_PAYROLL_MANAGER,
        ],
      },
      {
        icon: null,
        name: "To Be Scheduled",
        path: `/work-orders/pending`,
        component: WorkOrders,
        sidebar: true,
        roles: [
          USER_ROLE_ADMIN,
          USER_ROLE_EXECUTIVE,
          USER_ROLE_MANAGER,
          USER_ROLE_REGIONAL_MANAGER,
          USER_ROLE_PAYROLL_MANAGER,
        ],
      },
      {
        icon: null,
        name: "Scheduled",
        path: `/work-orders/scheduled`,
        component: WorkOrders,
        sidebar: true,
        roles: [
          USER_ROLE_ADMIN,
          USER_ROLE_EXECUTIVE,
          USER_ROLE_MANAGER,
          USER_ROLE_REGIONAL_MANAGER,
          USER_ROLE_DEVELOPERS,
          USER_ROLE_PAYROLL_MANAGER,
        ],
      },
      {
        icon: null,
        name: "In-Progress",
        path: `/work-orders/in-progress`,
        component: WorkOrders,
        sidebar: true,
        roles: [
          USER_ROLE_ADMIN,
          USER_ROLE_EXECUTIVE,
          USER_ROLE_MANAGER,
          USER_ROLE_REGIONAL_MANAGER,
          USER_ROLE_DEVELOPERS,
          USER_ROLE_PAYROLL_MANAGER,
        ],
      },
      {
        icon: null,
        name: "Waiting For Parts",
        path: `/work-orders/waiting-for-parts`,
        component: WorkOrders,
        sidebar: true,
        roles: [
          USER_ROLE_ADMIN,
          USER_ROLE_EXECUTIVE,
          USER_ROLE_MANAGER,
          USER_ROLE_REGIONAL_MANAGER,
          USER_ROLE_DEVELOPERS,
          USER_ROLE_PAYROLL_MANAGER,
        ],
      },
      {
        icon: null,
        name: "Completed",
        path: `/work-orders/completed`,
        component: WorkOrders,
        sidebar: true,
        roles: [
          USER_ROLE_ADMIN,
          USER_ROLE_EXECUTIVE,
          USER_ROLE_MANAGER,
          USER_ROLE_REGIONAL_MANAGER,
          USER_ROLE_DEVELOPERS,
          USER_ROLE_PAYROLL_MANAGER,
        ],
      },
      {
        icon: null,
        name: "Ready to Invoice",
        path: `/work-orders/ready-to-invoice`,
        component: WorkOrders,
        sidebar: true,
      },
      {
        icon: null,
        name: "Invoiced",
        path: `/work-orders/invoiced`,
        component: WorkOrders,
        sidebar: true,
      },
    ],
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: faCalendar,
    sidebar: true,
    roles: [
      USER_ROLE_ADMIN,
      USER_ROLE_EXECUTIVE,
      USER_ROLE_MANAGER,
      USER_ROLE_REGIONAL_MANAGER,
      USER_ROLE_DEVELOPERS,
      USER_ROLE_PAYROLL_MANAGER,
    ],
    entries: [
      {
        icon: null,
        name: "Schedule",
        path: `/calendar/schedule`,
        component: Schedule,
        sidebar: true,
      },
      {
        icon: null,
        name: "Recurring Jobs",
        path: `/calendar/recurring-jobs`,
        component: RecurringWorkOrders,
        sidebar: true,
      },
    ],
  },
  {
    path: "/customers",
    name: "Customers",
    icon: faUsers,
    sidebar: true,
    roles: [
      USER_ROLE_ADMIN,
      USER_ROLE_EXECUTIVE,
      USER_ROLE_MANAGER,
      USER_ROLE_REGIONAL_MANAGER,
      USER_ROLE_PAYROLL_MANAGER,
    ],
    entries: [
      {
        icon: null,
        name: "Customers",
        path: `/customers/all`,
        component: Customers,
        sidebar: true,
      },
      {
        icon: null,
        name: "Cranes",
        path: `/customers/cranes`,
        component: Cranes,
        sidebar: true,
      },
    ],
  },
  {
    path: `/customers/:customerId/details`,
    name: "Customers Details",
    component: CustomerDetails,
  },
  {
    path: `/work-orders/:workOrderId/details`,
    name: "Work Order Details",
    component: WorkOrderDetails,
  },
  {
    name: "Reports",
    icon: faArchive,
    path: "/report",
    sidebar: true,
    roles: [
      USER_ROLE_ADMIN,
      USER_ROLE_EXECUTIVE,
      USER_ROLE_MANAGER,
      USER_ROLE_REGIONAL_MANAGER,
      USER_ROLE_CONTROLLER,
      USER_ROLE_DEVELOPERS,
      USER_ROLE_PAYROLL_MANAGER,
    ],
    entries: [
      {
        icon: null,
        name: "Payroll",
        path: `/report/payroll`,
        component: PayrollReport,
        sidebar: true,
      },
      {
        icon: null,
        name: "Technician Daily Report",
        path: `/report/daily-worktime`,
        component: TechnicianDailyReport,
        sidebar: true,
      },
      {
        icon: null,
        name: "Job Cost Report",
        path: `/report/labor-cost`,
        component: JobCostReport,
        sidebar: true,
      },
      {
        icon: null,
        name: "Daily Usage Report",
        path: `/report/daily-usage-report`,
        component: DailyUsageReport,
        sidebar: true,
      },
      {
        icon: null,
        name: "PayCor",
        path: `/report/pay-cor`,
        component: PayCorReport,
        sidebar: true,
      },
    ],
  },
  {
    name: "Admin",
    icon: faCogs,
    path: "/admin",
    sidebar: true,
    roles: [
      USER_ROLE_ADMIN,
      USER_ROLE_EXECUTIVE,
      USER_ROLE_MANAGER,
      USER_ROLE_REGIONAL_MANAGER,
      USER_ROLE_PAYROLL_MANAGER,
    ],
    entries: [
      {
        icon: null,
        name: "General Settings",
        path: `/admin/general`,
        component: GeneralSettings,
        sidebar: true,
        roles: [USER_ROLE_EXECUTIVE],
      },
      {
        icon: null,
        name: "Users",
        path: `/admin/users`,
        component: Users,
        sidebar: true,
      },
      {
        icons: null,
        name: "Calendar Grouping",
        path: `/admin/calendar-grouping`,
        component: CalendarGrouping,
        sidebar: true,
      },
      {
        icon: null,
        name: "Insp. Points Manager",
        path: `/admin/inspection-points`,
        component: InspectionPointsManager,
        sidebar: true,
      },
      {
        icon: null,
        name: "Payroll Management",
        path: `/admin/payroll-management`,
        component: PayrollManagement,
        sidebar: true,
        roles: [
          USER_ROLE_ADMIN,
          USER_ROLE_EXECUTIVE,
          USER_ROLE_PAYROLL_MANAGER,
        ],
      },
      {
        icon: null,
        name: "Email Notifications",
        path: `/admin/email-notifications`,
        component: EmailNotifications,
        sidebar: true,
        roles: [
          USER_ROLE_ADMIN,
          USER_ROLE_EXECUTIVE,
          USER_ROLE_MANAGER,
          USER_ROLE_REGIONAL_MANAGER,
          USER_ROLE_PAYROLL_MANAGER,
        ],
      },
      {
        icon: null,
        name: "Activity Emails",
        path: `/admin/activity-emails`,
        component: ActivityEmails,
        sidebar: true,
        roles: [USER_ROLE_ADMIN, USER_ROLE_EXECUTIVE],
      },
      {
        icon: null,
        name: "Trip dates without lead",
        path: `/admin/no-lead-trips`,
        component: NoLeadTrips,
        sidebar: true,
        roles: [
          USER_ROLE_ADMIN,
          USER_ROLE_EXECUTIVE,
          USER_ROLE_MANAGER,
          USER_ROLE_REGIONAL_MANAGER,
          USER_ROLE_PAYROLL_MANAGER,
        ],
      },
      {
        icon: null,
        name: "Trip dates without lead",
        path: `/admin/no-lead-trips/:workOrderId`,
        component: NoLeadTrips,
        sidebar: false,
        roles: [
          USER_ROLE_ADMIN,
          USER_ROLE_EXECUTIVE,
          USER_ROLE_MANAGER,
          USER_ROLE_REGIONAL_MANAGER,
          USER_ROLE_PAYROLL_MANAGER,
        ],
      },
      {
        icon: null,
        name: "Audit of Merged Locations",
        path: `/admin/audit-merged-locations`,
        component: AuditMergedLocations,
        sidebar: true,
        roles: [
          USER_ROLE_ADMIN,
          USER_ROLE_EXECUTIVE,
          USER_ROLE_MANAGER,
          USER_ROLE_REGIONAL_MANAGER,
          USER_ROLE_PAYROLL_MANAGER,
        ],
      },
    ],
  },
  {
    path: `/cranes/:craneId/details`,
    name: "Crane Details",
    component: Crane,
  },
];

export const authRoutes = [
  {
    path: "/auth/sign-in",
    name: "Ingresar",
    component: SignIn,
  },
];

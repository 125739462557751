import React from "react";
import moment from "moment";
import { ListGroup, ListGroupItem } from "reactstrap";
import { createRoot } from "react-dom/client";
import * as XLSX from "xlsx";
import Papa from "papaparse";

const COLORS = {
  1: "danger",
  2: "tertiary",
  3: "success",
  4: "warning",
  5: "secondary",
};

const TYPE_INSPECTION = "Inspection";
const TYPE_MODIFICATION = "Modification";
const TYPE_SERVICE_CALL = "Service Call";
const TYPE_REPAIR = "Repair";
const TYPE_INSTALLATION = "Installation";
const TYPE_REBUILD = "Rebuild";
const TYPE_WARRANTY = "Warranty";

export const utils = {
  buildQueryString: (data) =>
    Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&"),
  formatCurrency: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits,
    }).format(number),
  formatDecimal: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", { maximumFractionDigits }).format(number),
  formatPercent: (progress, total) =>
    `${total ? ((progress / total) * 100).toFixed(2) : 0}%`,
  formatDate: (date, format = "YYYY-MM-DD") =>
    (date ? moment(date) : moment()).format(format),
  formatDateTime: (date, format = "YYYY-MM-DD, h:mm a") =>
    (date ? moment(date) : moment()).format(format),
  capitalize: (text) =>
    text.charAt(0).toUpperCase() + text.toLowerCase().slice(1),
  nameInitials: (name) => {
    if (!/(.+)( )+(.+)/.test(name)) {
      return `${name[0]}${name[1] || ""}`;
    }
    const [, , , lastname] = name.match(/(.+)( )+(.+)/);
    return `${name[0]}${lastname[0]}`;
  },
  formatDateAlt: (date) => {
    if (moment(date).isSame(moment(), "date")) {
      return "Today";
    } else if (moment(date).isSame(moment().add(-1, "day"), "date")) {
      return "Yesterday";
    } else {
      return moment(date).format("MM/DD/YYYY");
    }
  },
  getAddress: (customerLocation) =>
    `${
      customerLocation?.shipToAddress1
        ? `${customerLocation?.shipToAddress1}${
            customerLocation?.shipToAddress2
              ? `, ${customerLocation?.shipToAddress2}`
              : ""
          }${
            customerLocation?.shipToAddress3
              ? `, ${customerLocation?.shipToAddress3}`
              : ""
          }`
        : "-"
    } - ${customerLocation?.shipToCity}, ${customerLocation?.shipToState}, ${
      customerLocation?.shipToZipCode
    }`,
  workOrderStatusColor: (statusId) => COLORS[statusId],
  sectionMeetsCondition: (
    craneInspection,
    index,
    section,
    crane,
    sections = []
  ) => {
    const meetsInspectionType = section.condition?.inspectionType
      ? craneInspection.type === section.condition.inspectionType
      : true;
    const meetsCraneStructure = section.condition?.craneStructure
      ? Boolean(section.condition.craneStructure.exists) ===
          Boolean(crane.craneStructure) &&
        Boolean(section.condition.craneStructure.exists) === true
        ? !section.condition.craneStructure.craneStructureTypeId &&
          section.name.includes("{{") &&
          section.name.includes("}}")
          ? !sections.some(
              (s) =>
                s.name === crane.craneStructure.craneStructureType.name &&
                craneInspection.type === s.condition.inspectionType
            )
          : true
        : false
      : true;
    const meetsCraneControlExistance = section.condition?.craneControl
      ? section.condition.craneControl.exists === Boolean(crane.controls)
      : true;
    const meetsCraneControlControlId = section.condition?.craneControl
      ? crane.controls.find(
          (c) => c.id === section.condition.craneControl.controlId
        )
      : true;
    const meetsCraneHoistTrolley =
      section.condition?.craneHoist && crane.craneHoists[index]
        ? crane.craneHoists[index].withTrolley ===
          section.condition.craneHoist.hasTrolley
        : true;
    const meetsCraneWithRunway = section.condition?.crane
      ? crane.withRunway === section.condition.crane.withRunway
      : true;
    const meetsSpecificStructure = section.condition?.craneStructure
      ? section.condition.craneStructure.craneStructureTypeId
        ? crane?.craneStructure &&
          crane.craneStructure.craneStructureTypeId ===
            section.condition.craneStructure.craneStructureTypeId
        : true
      : true;
    return (
      meetsInspectionType &&
      meetsCraneStructure &&
      meetsCraneControlExistance &&
      meetsCraneControlControlId &&
      meetsCraneHoistTrolley &&
      meetsCraneWithRunway &&
      meetsSpecificStructure
    );
  },
  //sectionHasAnswers is for old inspections with sections that dont match conditions anymore but they did
  sectionHasAnswers: (answers, section) =>
    section.inspectionPoints.some((point) =>
      answers.find((answer) => answer.inspectionPointTypeId === point.id)
    ),
  getSectionName: (index, section, crane, workOrder) => {
    if (!section.name) {
      return null;
    }
    if (section.name.includes("{{") && section.name.includes("}}")) {
      const entityAttributeRegExp = /\{\{ (.+)\.(.+) \}\}/;
      const entityIteratorRegExp = /\{\{ (.+) <index> \}\}/;
      const entityPlainRegExp = /\{\{ (.+) \}\}/;
      if (entityAttributeRegExp.test(section.name)) {
        const [, entity, attribute] = section.name.match(entityAttributeRegExp);
        switch (entity) {
          case "craneStructureType":
            return (
              crane.craneStructure?.craneStructureType[attribute] ||
              "Hoist Only"
            );
          default:
            return section.name;
        }
      } else if (entityIteratorRegExp.test(section.name)) {
        const [, entity] = section.name.match(entityIteratorRegExp);
        return `${entity} ${index}`;
      } else if (entityPlainRegExp.test(section.name)) {
        const [, entityAttribute] = section.name.match(entityPlainRegExp);
        if (entityAttribute === "customerName") {
          return workOrder.customerWorkOrder[entityAttribute] || "-";
        }
        return section.name;
      } else {
        return section.name;
      }
    } else {
      return section.name;
    }
  },
  srcToFile: async (src, fileName, mimeType) =>
    fetch(src)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], fileName, { type: mimeType })),
  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  isBetaProd: () =>
    process.env.REACT_APP_ENV === "beta" ||
    process.env.REACT_APP_ENV === "production",
  onNotesClick: (notes, setInformationModal) =>
    setInformationModal({
      rawBody: true,
      isOpen: true,
      title: "Answer Notes",
      body: (
        <ListGroup>
          <ListGroupItem
            className="justify-content-center bg-lighter d-flex align-items-center"
            tag="div"
          >
            Notes
          </ListGroupItem>
          {notes.length ? (
            notes.map((note) => (
              <ListGroupItem
                key={note.id}
                className="d-flex justify-content-between"
                tag="div"
              >
                <small>{note.content}</small>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem className="d-flex justify-content-between" tag="div">
              <small className="text-muted">No notes to show</small>
            </ListGroupItem>
          )}
        </ListGroup>
      ),
    }),
  onPhotosClick: (photos, setInformationModal) =>
    setInformationModal({
      rawBody: true,
      isOpen: true,
      title: "Answer Photos",
      body: (
        <ListGroup>
          <ListGroupItem
            className="justify-content-center bg-lighter d-flex align-items-center"
            tag="div"
          >
            Photos
          </ListGroupItem>
          <ListGroupItem
            className="justify-content-start d-flex align-items-center flex-wrap"
            tag="div"
          >
            {photos.length ? (
              photos.map((photo) => (
                <div
                  key={photo.id}
                  className="figure img-thumbnail m-1"
                  style={{
                    backgroundPosition: "center",
                    backgroundImage: `url(${photo.content})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    height: "160px",
                    width: "160px",
                  }}
                ></div>
              ))
            ) : (
              <ListGroupItem
                className="d-flex justify-content-between"
                tag="div"
              >
                <small className="text-muted">No photos to show</small>
              </ListGroupItem>
            )}
          </ListGroupItem>
        </ListGroup>
      ),
    }),
  dateRangeIntersects: (startDate1, startDate2, endDate1, endDate2) => {
    const mStartDate1 = moment(startDate1);
    const mEndDate1 = moment(endDate1);
    const range1 = moment.range(mStartDate1, mEndDate1);
    const mStartDate2 = moment(startDate2);
    const mEndDate2 = moment(endDate2);
    const range2 = moment.range(mStartDate2, mEndDate2);
    const intersects = range1.overlaps(range2);
    return (
      intersects ||
      mStartDate1.isSame(mEndDate2) ||
      mEndDate1.isSame(mStartDate2)
    );
  },
  getEmployeeOptionWithPreferredName: (employee) =>
    employee
      ? {
          label: utils.getEmployeeLabelWithPreferredName(employee),
          value: employee.id,
        }
      : null,
  getEmployeeLabelWithPreferredName: (employee) =>
    employee.name ||
    `${employee.firstName} ${
      employee.preferredFirstName
        ? `(${employee.preferredFirstName}) ${employee.lastName}`
        : employee.lastName
    }`,
  getWoServiceRequested: (workOrder) =>
    workOrder.svcCommentText
      ? workOrder.svcCommentText
      : workOrder.comment
      ? workOrder.comment
      : "Not Provided",
  getJobTypeColor: (jobType) => {
    switch (jobType) {
      case TYPE_INSPECTION:
        return "primary";

      case TYPE_MODIFICATION:
        return "warning";

      case TYPE_SERVICE_CALL:
        return "success";

      case TYPE_REPAIR:
        return "secondary";

      case TYPE_INSTALLATION:
        return "info";

      case TYPE_REBUILD:
        return "tertiary";

      case TYPE_WARRANTY:
        return "lighter";

      default:
        return "secondary";
    }
  },
  sortDays: (a, b) =>
    parseInt(b.replaceAll("-", "")) - parseInt(a.replaceAll("-", "")),
  getLastEdited: (employeeWorkDays) => {
    const editedEWDs = employeeWorkDays.filter(
      (ewd) => ewd.updatedAt !== ewd.createdAt
    );
    if (!editedEWDs.length) {
      return null;
    }
    const lastEdited = moment.max(
      editedEWDs.map((ewd) =>
        ewd.updatedAt ? moment(ewd.updatedAt) : moment(ewd.createdAt)
      )
    );
    return lastEdited
      ? `Last Edited ${lastEdited.format("MM/DD/YYYY hh:mm:ss")}`
      : null;
  },
  isWorkDayEmpty: (employeeWorkDays) => {
    const attachments = employeeWorkDays.flatMap((e) => e.attachments);
    const craneInspections = employeeWorkDays.flatMap(
      (e) => e.craneInspections
    );
    const jsaAnswers = employeeWorkDays.flatMap((e) => e.jsaAnswers);
    const materialsOrdered = employeeWorkDays.flatMap(
      (e) => e.materialsOrdered
    );
    const workOrderMaterials = employeeWorkDays.flatMap(
      (e) => e.workOrderMaterials
    );
    const workOrderServiceNotes = employeeWorkDays.flatMap(
      (e) => e.workOrderServiceNotes
    );

    const dailyCustomerSignOff = employeeWorkDays.find(
      (day) =>
        day.signatureURL ||
        day.signatureCustomerName ||
        day.signatureNotProvidedReason
    );

    const workTimes = employeeWorkDays.flatMap((e) => e.workTimes);
    const postContracts = employeeWorkDays
      .map((e) => e.postContract)
      .filter(Boolean);
    const isEmpty =
      !attachments.length &&
      !craneInspections.length &&
      !jsaAnswers.length &&
      !materialsOrdered.length &&
      !workOrderMaterials.length &&
      !workOrderServiceNotes.length &&
      !workTimes.length &&
      !postContracts.length &&
      !dailyCustomerSignOff;
    return isEmpty;
  },
  getNameOfCraneStatus: ({ SERVICE_STATUS, craneServiceStatus }) => {
    return Object.keys(SERVICE_STATUS).find(
      (key) => SERVICE_STATUS[key] === craneServiceStatus
    );
  },
  calculateHours(
    startTime,
    endTime,
    dateIsWeekend,
    dateIsHoliday,
    hours,
    overtimeHours
  ) {
    const startMoment = moment(startTime, "YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const endMoment = moment(endTime, "YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const duration = moment.duration(endMoment.diff(startMoment));
    const durationHours = duration.asHours();
    const regularHours = 8;

    if (dateIsHoliday || dateIsWeekend) {
      overtimeHours = durationHours;
      hours = 0;

      return { hours, overtimeHours };
    }

    hours = durationHours;
    overtimeHours = 0;
    if (hours > regularHours) {
      overtimeHours = hours - regularHours;
      hours = regularHours;
    }

    return { hours, overtimeHours };
  },
  convertTo12HourFormat(time) {
    return moment.utc(time, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format("h:mm A");
  },
  formatTimeWithSeconds(time) {
    return moment.utc(time, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format("HH:mm");
  },
  renderComponentToNode: (Component, props) => {
    const node = document.createElement("div");
    const root = createRoot(node);
    root.render(<Component {...props} />);
    return node;
  },
};

export const CRANE_SERVICE_STATUS = {
  "IN SERVICE": "IN_SERVICE",
  "OUT OF SERVICE": "OUT_OF_SERVICE",
};

const generatePayrollData = (rawData) => {
  const employeesWithoutTimes = rawData.filter(
    (entry) => !entry.workTimes.length && !entry.otherTimes.length
  );
  const times = rawData.flatMap((entry) => entry.payRoll);

  const shouldShowDoubletime = times.some(
    (entry) => entry.IS_CALIFORNIA_PAYROLL
  );

  const data = times
    .sort((x, y) => {
      const yDate = y.date || y.employeeWorkDay.date;
      const xDate = x.date || x.employeeWorkDay.date;
      return moment(yDate).isBefore(xDate, "date") ? 1 : -1;
    })
    .map((entry) => {
      const employee =
        entry.employee || entry.employeeWorkDay?.workOrderEmployee?.employee;
      return {
        "Employee Id": employee?.id || "Not Set",
        "Employee Number": employee?.employeeId || "Not Set",
        "Employee Name": employee
          ? employee.firstName + " " + employee.lastName
          : "Not Set",
        "Social Security Number": "",
        "Date Worked": entry.date || entry.employeeWorkDay.date,
        "Regular Hours": entry.REG,
        "Overtime Hours": entry.OT,
        "Training Hours": entry.TRAINING.ot + entry.TRAINING.reg,
        ...(shouldShowDoubletime && { "Doubletime Hours": entry.DOUBLE }),
        "Hours Code": "",
        IS_CALIFORNIA_PAYROLL: entry.IS_CALIFORNIA_PAYROLL,
        "Coded Hours": "",
        "Amount Code": "",
        "Coded Amount": "",
        "Deduction Code": "",
        "Deduction Amount": "",
        "Deduction Exception Rate": "",
        "Base Department": "",
        "Exception Department": "",
        "Exception Rate": "",
        "Rate Sequence": "",
        "Shift Premium": "",
        "Pay Number": "",
        "Pay Week": "",
        "Tax Frequency": "",
        "Gross Receipts": "",
        "Labor Codes Category1": "",
        "Labor Codes Code1": "",
        "Labor Codes Category2": "",
        "Labor Codes Code2": "",
        "Labor Codes Category3": "",
        "Labor Codes Code3": "",
        "Labor Codes Category4": "",
        "Labor Codes Code4": "",
        "Labor Codes Category5": "",
        "Labor Codes Code5": "",
        "Labor Codes Category6": "",
        "Labor Codes Code6": "",
        "NonDiscretionaryBonus Start Date": "",
        "NonDiscretionaryBonus End Date": "",
        "Client Defined Value": "",
        "Check Type": "",
        "Tax Code": "",
        "Tax Amount": "",
        "Check Number": "",
      };
    });

  const dataWithoutTimes = employeesWithoutTimes.map((entry) => {
    const item = {
      "Employee Number": entry.employeeId || "Not Set",
      "Employee Name": entry.firstName + " " + entry.lastName,
      "Social Security Number": "",
      "Date Worked": "",
      "Regular Hours": "",
      "Overtime Hours": "",
      "Training Hours": "",
      "Hours Code": "",
      "Coded Hours": "",
      "Amount Code": "",
      "Coded Amount": "",
      "Deduction Code": "",
      "Deduction Amount": "",
      "Deduction Exception Rate": "",
      "Base Department": "",
      "Exception Department": "",
      "Exception Rate": "",
      "Rate Sequence": "",
      "Shift Premium": "",
      "Pay Number": "",
      "Pay Week": "",
      "Tax Frequency": "",
      "Gross Receipts": "",
      "Labor Codes Category1": "",
      "Labor Codes Code1": "",
      "Labor Codes Category2": "",
      "Labor Codes Code2": "",
      "Labor Codes Category3": "",
      "Labor Codes Code3": "",
      "Labor Codes Category4": "",
      "Labor Codes Code4": "",
      "Labor Codes Category5": "",
      "Labor Codes Code5": "",
      "Labor Codes Category6": "",
      "Labor Codes Code6": "",
      "NonDiscretionaryBonus Start Date": "",
      "NonDiscretionaryBonus End Date": "",
      "Client Defined Value": "",
      "Check Type": "",
      "Tax Code": "",
      "Tax Amount": "",
      "Check Number": "",
    };
    if (!utils.isBetaProd()) {
      item.Employee = entry.firstName + " " + entry.lastName;
    }
    return item;
  });

  return [...data, ...dataWithoutTimes];
};

export const onExportCSV = (rawData, monday) => {
  const csvFinalData = generatePayrollData(rawData);

  csvFinalData.sort((a, b) => {
    const lastNameA = a["LastName"] ? a["LastName"].toLowerCase() : "";
    const lastNameB = b["LastName"] ? b["LastName"].toLowerCase() : "";

    if (lastNameA && !lastNameB) return -1;
    if (!lastNameA && lastNameB) return 1;
    if (!lastNameA && !lastNameB) return 0;

    return lastNameA < lastNameB ? -1 : lastNameA > lastNameB ? 1 : 0;
  });

  csvFinalData.forEach((entry) => {
    if (entry["Employee Id"]) {
      delete entry["Employee Id"];
    }
    delete entry.FirstName;
    delete entry.LastName;
    delete entry.IS_CALIFORNIA_PAYROLL;
  });

  const CSVDownloadData = Papa.unparse(csvFinalData, { delimiter: "," });
  const filename = `PayrollReport_${monday.format("MM/DD/YYYY")}`;
  const hiddenElement = document.createElement("a");
  hiddenElement.href =
    "data:text/csv; charset=utf-8,\ufeff" + encodeURIComponent(CSVDownloadData);
  hiddenElement.target = "_self";
  hiddenElement.download = `${filename}.csv`;
  hiddenElement.click();
};

export const onExportXLS = (rawData, monday) => {
  const excelFinalData = generatePayrollData(rawData);

  excelFinalData.sort((a, b) => {
    const lastNameA = a["LastName"] ? a["LastName"].toLowerCase() : "";
    const lastNameB = b["LastName"] ? b["LastName"].toLowerCase() : "";

    if (lastNameA && !lastNameB) return -1;
    if (!lastNameA && lastNameB) return 1;
    if (!lastNameA && !lastNameB) return 0;

    return lastNameA < lastNameB ? -1 : lastNameA > lastNameB ? 1 : 0;
  });

  excelFinalData.forEach((entry) => {
    if (entry["Employee Id"]) {
      delete entry["Employee Id"];
    }
    delete entry.FirstName;
    delete entry.LastName;
  });

  const ws = XLSX.utils.json_to_sheet(excelFinalData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "PayrollReport");
  const filename = `PayrollReport_${monday.format("MM/DD/YYYY")}.xlsx`;
  XLSX.writeFile(wb, filename);
};
